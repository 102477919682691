import { render, staticRenderFns } from "./ClientListComponent.vue?vue&type=template&id=6a8f8c8e&scoped=true&"
import script from "./ClientListComponent.vue?vue&type=script&lang=ts&"
export * from "./ClientListComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a8f8c8e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VCol,VDataTable,VIcon,VRow,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/uSacGCCn/0/ucarok/mobileservices-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6a8f8c8e')) {
      api.createRecord('6a8f8c8e', component.options)
    } else {
      api.reload('6a8f8c8e', component.options)
    }
    module.hot.accept("./ClientListComponent.vue?vue&type=template&id=6a8f8c8e&scoped=true&", function () {
      api.rerender('6a8f8c8e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/contact-center/tabs/ClientTabSubComponents/ClientListComponent.vue"
export default component.exports