var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "div",
        {
          staticStyle: {
            color: "black",
            width: "130px !important",
            "font-size": "0.85em",
          },
        },
        [
          _c(
            "span",
            {
              staticStyle: { cursor: "pointer", "margin-top": "3px" },
              on: {
                click: function ($event) {
                  return _vm.showMessageDialog()
                },
              },
            },
            [
              _c(
                "v-icon",
                { staticStyle: { color: "black", "margin-left": "15px" } },
                [_vm._v("mdi-message-processing")]
              ),
              _c(
                "span",
                {
                  staticStyle: { "font-size": "0.75em", "font-weight": "bold" },
                },
                [_vm._v("SMS")]
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticStyle: { cursor: "pointer", "margin-top": "3px" },
              on: {
                click: function ($event) {
                  return _vm.makeCall()
                },
              },
            },
            [
              _c(
                "v-icon",
                { staticStyle: { color: "black", "margin-left": "15px" } },
                [_vm._v("mdi-phone")]
              ),
              _c(
                "span",
                {
                  staticStyle: { "font-size": "0.75em", "font-weight": "bold" },
                },
                [_vm._v("CALL")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("message-dialog-component", {
        attrs: {
          "contact-full-name": _vm.contactFullName,
          "contact-phone-number": _vm.contactPhoneNumber,
          "show-message-dlg": _vm.showSendMessageDialog,
        },
        on: {
          hideSendMessageDialog: function ($event) {
            _vm.showSendMessageDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }