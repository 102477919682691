var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "no-gutters" },
    [
      _c(
        "v-col",
        {
          staticClass: "flex-grow-1 flex-shrink-0 my-3",
          staticStyle: { border: "1px solid #1fae79", "border-radius": "3px" },
          attrs: { offset: "3", cols: "6" },
        },
        [
          _c(
            "v-responsive",
            {
              staticClass: "overflow-y-hidden fill-height",
              attrs: { height: "500" },
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "d-flex flex-column fill-height",
                  attrs: { flat: "" },
                },
                [
                  _c(
                    "v-card-title",
                    { staticStyle: { "border-bottom": "3px solid #1fae79" } },
                    [_vm._v("Messages")]
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "flex-grow-1 overflow-y-auto pt-3 pb-2" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.loadingClientCcMessages,
                              expression: "loadingClientCcMessages",
                            },
                          ],
                          staticClass:
                            "d-flex w-100 justify-center align-center",
                        },
                        [
                          _c("v-progress-circular", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.loadingClientCcMessages,
                                expression: "loadingClientCcMessages",
                              },
                            ],
                            attrs: { indeterminate: "", color: "green" },
                          }),
                        ],
                        1
                      ),
                      _vm.clientCcMessages.length === 0 &&
                      _vm.loadingClientCcMessages === false
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex w-100 justify-center align-center",
                            },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("There aren't message to show."),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._l(_vm.clientCcMessages, function (msg) {
                        return [
                          _c(
                            "div",
                            {
                              key: msg.index,
                              class: [
                                msg.from.includes("customer")
                                  ? "d-flex flex-row-reverse ml-12"
                                  : "mr-12",
                              ],
                            },
                            [
                              _c(
                                "v-chip",
                                {
                                  staticClass: "pa-4 mb-2",
                                  staticStyle: {
                                    height: "auto",
                                    "white-space": "normal",
                                    width: "100%",
                                  },
                                  attrs: {
                                    color: msg.from.includes("customer")
                                      ? "primary"
                                      : "",
                                    dark: "",
                                  },
                                },
                                [
                                  _c(
                                    "table",
                                    { staticStyle: { width: "100%" } },
                                    [
                                      _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "font-weight": "bold",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  msg.from.includes("customer")
                                                    ? msg.from ===
                                                      "customer_web_chat"
                                                      ? "Web Chat: "
                                                      : "SMS: "
                                                    : "Agent: "
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                          },
                                          [
                                            _c("sub", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    new Date(
                                                      msg.date.toString()
                                                    ).toLocaleString("en-CA")
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                      _c("tr", [
                                        _c("td", { attrs: { colspan: "2" } }, [
                                          _vm._v(" " + _vm._s(msg.body) + " "),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }