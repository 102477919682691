var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _vm.images.length
        ? _c(
            "v-card",
            { attrs: { "max-width": "100%", outlined: "" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    _vm._l(_vm.images, function (image, idx) {
                      return _c(
                        "v-col",
                        { key: idx, attrs: { cols: "6" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c("v-img", {
                                staticClass: "white--text align-end",
                                attrs: {
                                  src: image.preview,
                                  contain: "",
                                  gradient:
                                    "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
                                  height: "200px",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showPreview(image.preview)
                                  },
                                },
                              }),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(image.file.name)),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteFile(image.file)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "red" } },
                                        [_vm._v("mdi-delete")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-space-between" },
                [
                  _c(
                    "v-btn",
                    { attrs: { color: "primary", text: "" } },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v(" mdi-image-plus "),
                      ]),
                      _c(
                        "label",
                        {
                          staticStyle: { color: "#12945F" },
                          attrs: { for: "imageInputFile" },
                        },
                        [_vm._v("Add more")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.files = []
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v(" mdi-delete-sweep "),
                      ]),
                      _vm._v(" Delete All "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.images.length,
              expression: "!images.length",
            },
          ],
        },
        [
          _c(
            "div",
            {
              class: ["dropZone", _vm.dragging ? "dropZone-over" : ""],
              on: {
                dragenter: function ($event) {
                  _vm.dragging = true
                },
                dragleave: function ($event) {
                  _vm.dragging = false
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "dropZone-info", on: { drag: _vm.onChange } },
                [
                  _c("span", {
                    staticClass: "mdi mdi-24px mdi-cloud-upload dropZone-title",
                    staticStyle: { position: "relative", top: "3px" },
                  }),
                  _c("span", { staticClass: "dropZone-title" }, [
                    _vm._v(" Drop file or click to upload"),
                  ]),
                  _c("div", { staticClass: "dropZone-upload-limit-info" }, [
                    _c("div", [
                      _vm._v(
                        "[Only " +
                          _vm._s(this.maxUploadImages) +
                          " image files]"
                      ),
                    ]),
                    _c("div", [_vm._v("[Maximum file size: 5 MB]")]),
                  ]),
                ]
              ),
              _c("input", {
                ref: "fileUpload",
                attrs: {
                  id: "imageInputFile",
                  accept: "image/*",
                  multiple: "",
                  type: "file",
                },
                on: { change: _vm.onChange },
              }),
            ]
          ),
        ]
      ),
      _c(
        "v-overlay",
        {
          attrs: { value: _vm.preview.overlay },
          on: {
            click: function ($event) {
              _vm.preview.overlay = false
            },
          },
        },
        [
          _c("v-img", {
            staticClass: "white--text align-end",
            attrs: {
              src: _vm.preview.image || "",
              contain: "",
              gradient: "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
              height: "100vh",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }