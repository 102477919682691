var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "460", persistent: "" },
      model: {
        value: _vm.showAddNoteDlg,
        callback: function ($$v) {
          _vm.showAddNoteDlg = $$v
        },
        expression: "showAddNoteDlg",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "d-flex justify-lg-space-between" },
            [
              _c("span", { staticClass: "ml-1" }, [_vm._v("Add note")]),
              _c("i", {
                staticClass: "mdi mdi-close",
                staticStyle: { "font-size": "24px", color: "#72767E" },
                on: { click: _vm.closeDialog },
              }),
            ]
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-0 pb-0" },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "pl-4" }, [
                    _c("span", [
                      _vm._v("Appointment: " + _vm._s(_vm.appointmentId) + " "),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-form",
                { ref: "messageForm", attrs: { id: "message-form" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "pt-1" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0 pt-0" },
                        [
                          _c("v-textarea", {
                            attrs: {
                              disabled: _vm.textAreaDisabled,
                              rules: [
                                function (v) {
                                  return !!v || "A note is required"
                                },
                              ],
                              outlined: "",
                              placeholder: "Write your note here.",
                            },
                            model: {
                              value: _vm.noteToAdd,
                              callback: function ($$v) {
                                _vm.noteToAdd = $$v
                              },
                              expression: "noteToAdd",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-0 mb-2" },
                [
                  _c(
                    "v-col",
                    [
                      _c("image-loader", {
                        on: { "images-loaded": _vm.onLoadedImages },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            {
              staticClass: "pr-6",
              staticStyle: { "justify-content": "right" },
            },
            [
              _c(
                "v-btn",
                {
                  staticStyle: { "font-family": "Rubik" },
                  attrs: {
                    disabled: _vm.noteToAdd === "",
                    loading: _vm.addingNote,
                    color: "u-background-primary white--text text-none",
                    elevation: "0",
                    form: "login-form",
                  },
                  on: { click: _vm.addNote },
                },
                [_vm._v(" Add ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }