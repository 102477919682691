var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "col-12" },
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            staticStyle: { width: "100% !important" },
            attrs: {
              headers: _vm.headers,
              items: _vm.tickets,
              "items-per-page": 15,
              loading: _vm.loading,
              "loading-text": "Loading... Please wait",
              search: _vm.search,
              "sort-by": ["ticketNumber"],
              "sort-desc": [true],
              "single-expand": true,
              expanded: _vm.itemExpanded,
              "item-key": "ticketNumber",
              "show-expand": "",
            },
            on: {
              "update:expanded": function ($event) {
                _vm.itemExpanded = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "expanded-item",
                fn: function (ref) {
                  var headers = ref.headers
                  var item = ref.item
                  return [
                    _c("TicketDetailsComponent", {
                      key: item.id,
                      tag: "component",
                      attrs: { headers: headers, ticket: item },
                    }),
                  ]
                },
              },
              {
                key: "item.dueDate",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c("span", [
                      _vm._v(_vm._s(new Date(item.dueDate).toLocaleString())),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }