var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "col-12", attrs: { "no-gutters": "" } },
    [
      _c("v-col", [
        _c("div", [
          _c("strong", [_vm._v("Name:")]),
          _vm._v(
            " " +
              _vm._s(
                _vm.GetClientInfo.firstName + " " + _vm.GetClientInfo.lastName
              )
          ),
        ]),
        _c("div", [
          _c("strong", [_vm._v("Phone:")]),
          _vm._v(" " + _vm._s(_vm.GetClientInfo.phoneNumber)),
        ]),
        _c("div", [
          _c("strong", [_vm._v("Email:")]),
          _vm._v(" " + _vm._s(_vm.GetClientInfo.email)),
        ]),
      ]),
      _c(
        "v-col",
        { staticClass: "d-flex flex-row justify-lg-space-around" },
        [
          _c("span", { staticStyle: { "margin-top": "2px" } }, [
            _c("strong", [_vm._v("Contact Client:")]),
          ]),
          _c("message-and-call-component", {
            staticStyle: { "margin-top": "4px", "margin-left": "10px" },
            attrs: {
              "contact-full-name":
                _vm.GetClientInfo.firstName + " " + _vm.GetClientInfo.lastName,
              "contact-phone-number": _vm.GetClientInfo.phoneNumber,
              "agent-id": _vm.agentId,
            },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "d-flex justify-end" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                id: "buttonsContainer",
                color: "u-background-primary white--text text-none",
                elevation: "0",
                height: "56",
              },
              on: { click: _vm.goToAutoEstimate },
            },
            [_vm._v(" Create Appointment ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }