var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "pa-3" },
    [
      _c(
        "v-col",
        [
          _c(
            "v-tabs",
            {
              staticClass: "tabs-style",
              attrs: {
                "background-color": "tabs-background",
                dark: "",
                "icons-and-text": "",
              },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                "v-tab",
                { staticClass: "v-tab-style", attrs: { href: "#tab-1" } },
                [_vm._v(" Open ")]
              ),
              _c(
                "v-tab",
                { staticClass: "v-tab-style", attrs: { href: "#tab-2" } },
                [_vm._v(" Closed ")]
              ),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            _vm._l(3, function (i) {
              return _c(
                "v-tab-item",
                {
                  key: i,
                  staticClass: "tab-item-style mb-1",
                  attrs: { value: "tab-" + i },
                },
                [_c(_vm.tabs[i - 1], { tag: "component" })],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }