import { render, staticRenderFns } from "./ApptMessagesComponent.vue?vue&type=template&id=9fa62410&scoped=true&"
import script from "./ApptMessagesComponent.vue?vue&type=script&lang=ts&"
export * from "./ApptMessagesComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fa62410",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VCard,VCardActions,VCardText,VCardTitle,VCol,VContainer,VDialog,VIcon,VProgressCircular,VRow,VWindow,VWindowItem})


/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/uSacGCCn/0/ucarok/mobileservices-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9fa62410')) {
      api.createRecord('9fa62410', component.options)
    } else {
      api.reload('9fa62410', component.options)
    }
    module.hot.accept("./ApptMessagesComponent.vue?vue&type=template&id=9fa62410&scoped=true&", function () {
      api.rerender('9fa62410', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/contact-center/tabs/ClientTabSubComponents/ApptMessagesComponent.vue"
export default component.exports