var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "pa-0", attrs: { fluid: "" } }, [
    _vm.contactCenterClientState.showTechnicianDetailsWithId === -1
      ? _c(
          "div",
          [
            _c("technician-list-component"),
            _c("calendar-technician-appointment-component"),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "pa-5" },
          [
            _c(
              "v-row",
              [
                _c("v-col", [
                  _c(
                    "span",
                    { staticStyle: { cursor: "pointer" } },
                    [
                      _c("v-icon", { attrs: { color: "black" } }, [
                        _vm._v("mdi-arrow-left"),
                      ]),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#4c996e",
                            "font-weight": "bold",
                            "margin-top": "3px !important",
                          },
                          on: { click: _vm.goBack },
                        },
                        [_vm._v("Go Back")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _c(
              "v-row",
              { staticClass: "mt-0" },
              [
                _c(
                  "v-col",
                  { staticClass: "mt-0" },
                  [
                    _c("technician-details-view", {
                      attrs: { "agent-id": _vm.agentId },
                    }),
                    _c(
                      "v-row",
                      { staticClass: "pt-0" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "mt-0 pt-0" },
                          [
                            _c(
                              "v-tabs",
                              {
                                staticClass: "tabs-style",
                                attrs: {
                                  "background-color": "tabs-background",
                                  dark: "",
                                  "icons-and-text": "",
                                },
                                model: {
                                  value: _vm.tab,
                                  callback: function ($$v) {
                                    _vm.tab = $$v
                                  },
                                  expression: "tab",
                                },
                              },
                              [
                                _c(
                                  "v-tab",
                                  {
                                    staticClass: "v-tab-style",
                                    attrs: { href: "#tab-1" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          "Past Appointments " +
                                            (_vm.contactCenterClientState
                                              .technicianApptsInfo
                                              .technicianPastAppts > 0
                                              ? "(" +
                                                _vm.contactCenterClientState
                                                  .technicianApptsInfo
                                                  .technicianPastAppts +
                                                ")"
                                              : "")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-tab",
                                  {
                                    staticClass: "v-tab-style",
                                    attrs: { href: "#tab-2" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          "Upcoming Appointments " +
                                            (_vm.contactCenterClientState
                                              .technicianApptsInfo
                                              .technicianUpcomingAppts > 0
                                              ? "(" +
                                                _vm.contactCenterClientState
                                                  .technicianApptsInfo
                                                  .technicianUpcomingAppts +
                                                ")"
                                              : "")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-tab",
                                  {
                                    staticClass: "v-tab-style",
                                    attrs: { href: "#tab-3" },
                                  },
                                  [_vm._v(" Past Tickets ")]
                                ),
                                _c(
                                  "v-tab",
                                  {
                                    staticClass: "v-tab-style",
                                    attrs: { href: "#tab-4" },
                                  },
                                  [_vm._v(" Chat History ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-tabs-items",
                              {
                                model: {
                                  value: _vm.tab,
                                  callback: function ($$v) {
                                    _vm.tab = $$v
                                  },
                                  expression: "tab",
                                },
                              },
                              _vm._l(4, function (i) {
                                return _c(
                                  "v-tab-item",
                                  {
                                    key: i,
                                    staticClass: "tab-item-style",
                                    attrs: { value: "tab-" + i },
                                  },
                                  [
                                    _c(_vm.tabs[i - 1], {
                                      tag: "component",
                                      attrs: {
                                        "agent-id": _vm.agentId,
                                        "technician-id":
                                          _vm.contactCenterClientState
                                            .showTechnicianDetailsWithId,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }