var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-text-field", {
        staticClass: "pt-0 ml-4",
        staticStyle: { "max-width": "410px", "margin-top": "-30px" },
        attrs: {
          clearable: "",
          flat: "",
          "hide-details": "",
          "prepend-inner-icon": "mdi-magnify",
          label: "Search",
          loading: _vm.loading,
        },
        on: {
          input: function ($event) {
            _vm.isTyping = true
          },
        },
        model: {
          value: _vm.search,
          callback: function ($$v) {
            _vm.search = $$v
          },
          expression: "search",
        },
      }),
      _vm.clients.length > 0
        ? _c("v-data-table", {
            staticClass: "elevation-1 mt-5",
            attrs: {
              headers: _vm.headers,
              items: _vm.clients,
              "items-per-page": 15,
              "loading-text": "Loading... Please wait",
              "sort-by": "id",
              "sort-desc": true,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.actions",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-icon",
                        {
                          staticClass: "align-self-baseline",
                          on: {
                            click: function ($event) {
                              return _vm.showDetails(item)
                            },
                          },
                        },
                        [_vm._v(" mdi mdi-24px mdi-launch ")]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              1999056283
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }