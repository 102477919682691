var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("td", { attrs: { colspan: _vm.headers.length } }, [
    _c(
      "div",
      { staticClass: "d-flex flex-row justify-center" },
      [
        _vm.loading
          ? _c("v-progress-circular", {
              attrs: { indeterminate: "", color: "green" },
            })
          : _vm._e(),
      ],
      1
    ),
    !_vm.loading
      ? _c("div", { staticClass: "p-5" }, [
          _c(
            "div",
            { staticClass: "row", staticStyle: { padding: "20px" } },
            [
              _vm._l(_vm.ticketDetails.threads, function (thread) {
                return _c(
                  "v-card",
                  {
                    key: thread.id,
                    staticClass: "mt-1 mb-1 col-5 ml-1 mr-1",
                    attrs: { outlined: "" },
                  },
                  [
                    _c(
                      "v-list-item",
                      { attrs: { "three-line": "" } },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "v-list-item-title",
                              { staticClass: "mb-1" },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { left: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { icon: "" } },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "#198655",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-android-messages "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("span", [_vm._v("Thread Message")])]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(thread.author.name) +
                                    " " +
                                    _vm._s(_vm.formatDate(thread.createdTime)) +
                                    " "
                                ),
                              ],
                              1
                            ),
                            _c("v-list-item-content", [
                              _vm._v(_vm._s(thread.plainText)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _vm._l(_vm.ticketComments, function (ticketComment) {
                return _c(
                  "v-card",
                  {
                    key: ticketComment.id,
                    staticClass: "mt-1 mb-1 col-5 ml-1 mr-1",
                    attrs: { outlined: "" },
                  },
                  [
                    _c(
                      "v-list-item",
                      { attrs: { "three-line": "" } },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "v-list-item-title",
                              { staticClass: "mb-1" },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { left: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { icon: "" } },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "#198655",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-comment-outline "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("span", [_vm._v("Comment")])]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(ticketComment.commenter.name) +
                                    " " +
                                    _vm._s(
                                      _vm.formatDate(
                                        ticketComment.commentedTime
                                      )
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                            _c("v-list-item-content", {
                              domProps: {
                                innerHTML: _vm._s(ticketComment.content),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }