var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-12 pr-0" },
    [
      _c("v-text-field", {
        staticClass: "pt-2 ml-4",
        staticStyle: { "max-width": "410px" },
        attrs: {
          clearable: "",
          flat: "",
          "hide-details": "",
          label: "Search",
          "prepend-inner-icon": "mdi-magnify",
        },
        model: {
          value: _vm.search,
          callback: function ($$v) {
            _vm.search = $$v
          },
          expression: "search",
        },
      }),
      _c("v-data-table", {
        staticClass: "elevation-1 mt-5",
        attrs: {
          headers: _vm.headers,
          items: _vm.contactCenterState.notificationTabState.notifications,
          "items-per-page": 10,
          loading:
            _vm.contactCenterState.notificationTabState.loadingNotifications,
          options: _vm.options,
          search: _vm.search,
          "server-items-length":
            _vm.contactCenterState.notificationTabState.totalOfNotifications,
          "loading-text": "Loading... Please wait",
        },
        on: {
          "update:options": function ($event) {
            _vm.options = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item.status",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "v-chip",
                  {
                    staticClass: "px-2 p-3-12",
                    attrs: {
                      color: item.notificationAppointmentStatus
                        ? _vm.getStatusColor(
                            item.notificationAppointmentStatus.appointmentStatus
                              .display
                          )
                        : "red",
                      dark: "",
                      "x-small": "",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.notificationAppointmentStatus
                            ? item.notificationAppointmentStatus
                                .appointmentStatus.display
                            : ""
                        ) +
                        " "
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.client",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("table", [
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.sendSMS(
                                  item.client.name,
                                  item.client.phoneNumber
                                )
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { color: "#423f3f" } }, [
                              _vm._v(" mdi-chat-processing "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.makeCall(
                                  item.client.name,
                                  item.client.phoneNumber
                                )
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { color: "#423f3f" } }, [
                              _vm._v(" mdi-phone-in-talk "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("td", [_vm._v(_vm._s(item.client.name))]),
                  ]),
                ]),
              ]
            },
          },
          {
            key: "item.technician",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("table", [
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.sendSMS(
                                  item.technician.name,
                                  item.mechanic.phoneNumber
                                )
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { color: "#423f3f" } }, [
                              _vm._v(" mdi-chat-processing "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.makeCall(
                                  item.technician.name,
                                  item.mechanic.phoneNumber
                                )
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { color: "#423f3f" } }, [
                              _vm._v(" mdi-phone-in-talk "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("td", [_vm._v(_vm._s(item.technician.name))]),
                  ]),
                ]),
              ]
            },
          },
          {
            key: "item.notificationStatus",
            fn: function (ref) {
              var item = ref.item
              return [
                item.followStatus.agentId !== "-" &&
                item.followStatus.agentId !== _vm.agentId &&
                item.followStatus.ccFollowStatusType.name !== "Pending"
                  ? _c(
                      "v-chip",
                      {
                        staticClass: "font-weight-bold",
                        staticStyle: { color: "white" },
                        attrs: { color: _vm.getFollowStatusColor(item) },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(item.followStatus.ccFollowStatusType.name) +
                            " " +
                            _vm._s(
                              "(" + item.followStatus.agent.fullName + ")"
                            ) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
                item.notificationAppointmentStatus.appointmentStatus.code ===
                  2 &&
                item.cancellationReason !== null &&
                item.cancellationReason !== undefined
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticStyle: {
                                            cursor: "pointer",
                                            "margin-top": "3px",
                                            "margin-left": "5px",
                                          },
                                          attrs: { icon: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showCancellationReasonDetailsDialog(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { staticStyle: { color: "#423f3f" } },
                                        [_vm._v("mdi-note")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("span", [_vm._v("Cancellation Reason")])]
                    )
                  : _vm._e(),
                item.followStatus.agentId === "-" ||
                item.followStatus.agentId === _vm.agentId ||
                item.followStatus.ccFollowStatusType.name === "Pending"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row justify-space-between align-center col-12 pt-1 pl-0",
                      },
                      [
                        item.followStatus.ccFollowStatusType.name !==
                          "Cancellation Reason" &&
                        item.followStatus.ccFollowStatusType.name !==
                          "Completed"
                          ? _c(
                              "div",
                              {
                                class:
                                  item.followStatus.ccFollowStatusType.name ===
                                    "Pending" ||
                                  item.followStatus.agentId === "-"
                                    ? "col-7 pl-0"
                                    : "col-9 pl-0",
                                staticStyle: {
                                  "max-height": "72px !important",
                                },
                              },
                              [
                                _c("v-select", {
                                  attrs: {
                                    items: _vm.getFollowStatusOptions(item),
                                    "item-text": "name",
                                    "item-value": "id",
                                    outlined: "",
                                    "return-object": "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onFollowStatusOption(
                                        $event,
                                        item
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      item.followStatus.ccFollowStatusType.id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        item.followStatus.ccFollowStatusType,
                                        "id",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "item.followStatus.ccFollowStatusType.id",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        item.followStatus.ccFollowStatusType.name ===
                        "Cancellation Reason"
                          ? _c(
                              "div",
                              {
                                class:
                                  item.followStatus.agentId === "-"
                                    ? "col-7"
                                    : "col-9",
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "col-12",
                                    staticStyle: { "font-size": "75%" },
                                    attrs: {
                                      color:
                                        "u-background-primary white--text text-none",
                                      elevation: "0",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showCancellationReasonDialog(
                                          item
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" Add Cancellation Reason ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        item.followStatus.ccFollowStatusType.name ===
                        "Completed"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "col-12 pl-0 d-flex flex-row justify-sm-space-between align-center",
                              },
                              [
                                _c(
                                  "v-chip",
                                  {
                                    staticClass: "ml-1 font-weight-bold",
                                    staticStyle: { color: "white" },
                                    attrs: { color: "green" },
                                  },
                                  [_vm._v("Completed (Me)")]
                                ),
                                item.notificationAppointmentStatus
                                  .appointmentStatus.code === 2
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticStyle: {
                                                            cursor: "pointer",
                                                            "margin-top": "3px",
                                                            "margin-left":
                                                              "5px",
                                                          },
                                                          attrs: { icon: "" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.showCancellationReasonDetailsDialog(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticStyle: {
                                                            color: "#423f3f",
                                                          },
                                                        },
                                                        [_vm._v("mdi-note")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v("Cancellation Reason"),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        item.followStatus.ccFollowStatusType.name !==
                          "Completed" &&
                        item.followStatus.ccFollowStatusType.name !==
                          "Pending" &&
                        item.followStatus.agentId !== "-"
                          ? _c(
                              "div",
                              { staticClass: "col-3" },
                              [
                                _c(
                                  "v-chip",
                                  {
                                    staticClass: "ml-1 font-weight-bold",
                                    staticStyle: { color: "white" },
                                    attrs: { color: "green" },
                                  },
                                  [_vm._v("Me")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        item.followStatus.ccFollowStatusType.name ===
                          "Pending" || item.followStatus.agentId === "-"
                          ? _c(
                              "div",
                              {
                                class:
                                  item.followStatus.ccFollowStatusType.name ===
                                    "Pending" ||
                                  item.followStatus.agentId === "-"
                                    ? "col-5"
                                    : "col-3",
                              },
                              [
                                _c(
                                  "v-chip",
                                  {
                                    staticClass: "font-weight-bold",
                                    staticStyle: { color: "white" },
                                    attrs: { color: "red" },
                                  },
                                  [_vm._v("No Agent")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item.noteActions",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("add-and-notes-component", {
                  attrs: {
                    "show-add-button": false,
                    "appointment-id": item.appointmentId,
                    "cc-agent-id": _vm.agentId,
                    ccNotesCount: item.ccNotesCount,
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c("message-dialog-component", {
        attrs: {
          "contact-full-name": _vm.makeSMSAndCall.contactFullName,
          "contact-phone-number": _vm.makeSMSAndCall.contactPhoneNumber,
          "show-message-dlg": _vm.makeSMSAndCall.showSendMessageDialog,
        },
        on: {
          hideSendMessageDialog: function ($event) {
            _vm.makeSMSAndCall.showSendMessageDialog = false
          },
        },
      }),
      _c("add-cancellation-reason-dialog-component", {
        attrs: {
          "appointment-id": _vm.selectNotification
            ? _vm.selectNotification.appointmentId
            : 0,
          "cc-agent-id": _vm.agentId,
          "show-add-cancellation-reason-dialog":
            _vm.showAddCancellationReasonDialog,
        },
      }),
      _c("cancellation-reason-details-dialog-component", {
        attrs: {
          "appointment-id": _vm.selectNotification
            ? _vm.selectNotification.appointmentId
            : 0,
          "show-cancellation-reason-details-dialog":
            _vm.showAddCancellationReasonDetailsDialog,
          content:
            _vm.selectNotification && _vm.selectNotification.cancellationReason
              ? _vm.selectNotification.cancellationReason.content
              : "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }