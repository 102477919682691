var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "460", persistent: "" },
      model: {
        value: _vm.showCancellationReasonDetailsDlg,
        callback: function ($$v) {
          _vm.showCancellationReasonDetailsDlg = $$v
        },
        expression: "showCancellationReasonDetailsDlg",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "d-flex justify-lg-space-between" },
            [
              _c("span", { staticClass: "ml-1" }, [
                _vm._v("Cancellation reason details"),
              ]),
              _c("i", {
                staticClass: "mdi mdi-close",
                staticStyle: { "font-size": "24px", color: "#72767E" },
                on: { click: _vm.closeDialog },
              }),
            ]
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-0 pb-0" },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "pl-4" }, [
                    _c("span", [
                      _vm._v("Appointment: " + _vm._s(_vm.appointmentId) + " "),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "pt-1" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-0 pt-0" },
                    [
                      _c("v-textarea", {
                        attrs: { outlined: "", value: _vm.content },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }