import { render, staticRenderFns } from "./ServicesComponent.vue?vue&type=template&id=5f812a08&scoped=true&"
import script from "./ServicesComponent.vue?vue&type=script&lang=ts&"
export * from "./ServicesComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f812a08",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAutocomplete,VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/uSacGCCn/0/ucarok/mobileservices-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f812a08')) {
      api.createRecord('5f812a08', component.options)
    } else {
      api.reload('5f812a08', component.options)
    }
    module.hot.accept("./ServicesComponent.vue?vue&type=template&id=5f812a08&scoped=true&", function () {
      api.rerender('5f812a08', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/contact-center/tabs/AvailableSlotsTabComponent/SubComponents/ServicesComponent.vue"
export default component.exports