var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "pa-0",
      staticStyle: { "min-height": "235px" },
      attrs: { fluid: "" },
    },
    [
      _vm.contactCenterClientState.showClientDetailsWithId === -1
        ? _c(
            "div",
            [
              _c(
                "v-row",
                { staticClass: "d-flex flex-row justify-end pt-10 pr-7" },
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: { "font-family": "Rubik" },
                      attrs: {
                        elevation: "0",
                        height: "48",
                        color: "u-background-primary white--text text-none",
                        loading: _vm.makingAClient,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.createUser()
                        },
                      },
                    },
                    [_vm._v(" Register New Client ")]
                  ),
                ],
                1
              ),
              _c("search-client-component"),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "pa-5" },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c(
                      "span",
                      { staticStyle: { cursor: "pointer" } },
                      [
                        _c("v-icon", { attrs: { color: "black" } }, [
                          _vm._v("mdi-arrow-left"),
                        ]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#4c996e",
                              "font-weight": "bold",
                              "margin-top": "3px !important",
                            },
                            on: { click: _vm.goBack },
                          },
                          [_vm._v("Go Back")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "mt-0" },
                    [
                      _c("client-details-component", {
                        attrs: { "agent-id": _vm.agentId },
                      }),
                      _c(
                        "v-row",
                        { staticClass: "pt-0" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mt-0 pt-0" },
                            [
                              _c(
                                "v-tabs",
                                {
                                  staticClass: "tabs-style",
                                  attrs: {
                                    "background-color": "tabs-background",
                                    dark: "",
                                    "icons-and-text": "",
                                  },
                                  model: {
                                    value: _vm.tab,
                                    callback: function ($$v) {
                                      _vm.tab = $$v
                                    },
                                    expression: "tab",
                                  },
                                },
                                [
                                  _c(
                                    "v-tab",
                                    {
                                      staticClass: "v-tab-style",
                                      attrs: { href: "#tab-1" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            "Past Appointments " +
                                              (_vm.contactCenterClientState
                                                .clientInfo.pastAppts > 0
                                                ? "(" +
                                                  _vm.contactCenterClientState
                                                    .clientInfo.pastAppts +
                                                  ")"
                                                : "")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      staticClass: "v-tab-style",
                                      attrs: { href: "#tab-2" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            "Upcoming Appointments " +
                                              (_vm.contactCenterClientState
                                                .clientInfo.upcomingAppts > 0
                                                ? "(" +
                                                  _vm.contactCenterClientState
                                                    .clientInfo.upcomingAppts +
                                                  ")"
                                                : "")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      staticClass: "v-tab-style",
                                      attrs: { href: "#tab-3" },
                                    },
                                    [_vm._v(" Tickets ")]
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      staticClass: "v-tab-style",
                                      attrs: { href: "#tab-4" },
                                    },
                                    [_vm._v(" Chat History ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-tabs-items",
                                {
                                  model: {
                                    value: _vm.tab,
                                    callback: function ($$v) {
                                      _vm.tab = $$v
                                    },
                                    expression: "tab",
                                  },
                                },
                                _vm._l(4, function (i) {
                                  return _c(
                                    "v-tab-item",
                                    {
                                      key: i,
                                      staticClass: "tab-item-style",
                                      attrs: { value: "tab-" + i },
                                    },
                                    [
                                      _c(_vm.tabs[i - 1], {
                                        tag: "component",
                                        attrs: {
                                          clientId:
                                            _vm.contactCenterClientState
                                              .showClientDetailsWithId,
                                          "agent-id": _vm.agentId,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "860" },
          model: {
            value: this.contactCenterClientState.showDialog,
            callback: function ($$v) {
              _vm.$set(this.contactCenterClientState, "showDialog", $$v)
            },
            expression: "this.contactCenterClientState.showDialog",
          },
        },
        [_c("register-client-component")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }