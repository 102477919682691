var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-text-field", {
        staticClass: "pt-2 ml-4",
        staticStyle: { "max-width": "410px", "margin-top": "-70px" },
        attrs: {
          clearable: "",
          flat: "",
          "hide-details": "",
          "prepend-inner-icon": "mdi-magnify",
          label: "Search",
        },
        model: {
          value: _vm.search,
          callback: function ($$v) {
            _vm.search = $$v
          },
          expression: "search",
        },
      }),
      _c("v-data-table", {
        staticClass: "elevation-1 mt-5",
        attrs: {
          headers: _vm.headers,
          items: _vm.appointments,
          "items-per-page": 10,
          loading: _vm.loading,
          "loading-text": "Loading... Please wait",
          search: _vm.search,
          options: _vm.options,
          "server-items-length": _vm.totalOfAppointments,
        },
        on: {
          "update:options": function ($event) {
            _vm.options = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item.status",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: {
                      right: "",
                      color: _vm.getStatusColor(item.status),
                      disabled:
                        item.status != "Needs your Approval" &&
                        item.status != "Needs you enter a new card",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-chip",
                                _vm._g(
                                  {
                                    staticClass: "ma-2 p-3-12",
                                    attrs: {
                                      "x-small": "",
                                      color: _vm.getStatusColor(item.status),
                                      dark: "",
                                      close:
                                        item.status == "Needs your Approval" ||
                                        item.status ==
                                          "Needs you enter a new card",
                                      "close-icon": "mdi-content-copy",
                                    },
                                    on: {
                                      "click:close": function ($event) {
                                        return _vm.copyConfirmationLink(item)
                                      },
                                    },
                                  },
                                  on
                                ),
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getStatusCaption(item.status)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("AppointmentConfirmationLink"))),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.clientName",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("table", [
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.sendSMS(
                                  item.clientName,
                                  item.clientPhone
                                )
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { color: "#423f3f" } }, [
                              _vm._v(" mdi-chat-processing "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.makeCall(
                                  item.clientName,
                                  item.clientPhone
                                )
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { color: "#423f3f" } }, [
                              _vm._v(" mdi-phone-in-talk "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("td", [_vm._v(_vm._s(item.clientName))]),
                  ]),
                ]),
              ]
            },
          },
          {
            key: "item.technician",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("table", [
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.sendSMS(
                                  item.technician,
                                  item.mechanicPhone
                                )
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { color: "#423f3f" } }, [
                              _vm._v(" mdi-chat-processing "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.makeCall(
                                  item.technician,
                                  item.mechanicPhone
                                )
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { color: "#423f3f" } }, [
                              _vm._v(" mdi-phone-in-talk "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("td", [_vm._v(_vm._s(item.technician))]),
                  ]),
                ]),
              ]
            },
          },
          {
            key: "item.actions",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "v-icon",
                  {
                    attrs: { color: "#423f3f" },
                    on: {
                      click: function ($event) {
                        return _vm.editMyAppointment(item)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.isAppointmentEditable(item)
                            ? "mdi-pencil"
                            : "mdi-eye"
                        ) +
                        " "
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.noteActions",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("add-and-notes-component", {
                  attrs: {
                    "appointment-id": item.id,
                    "cc-agent-id": _vm.agentId,
                    ccNotesCount: item.ccNotesCount,
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "490" },
          model: {
            value: this.$store.state.appointment.isEditAppointmentVisible,
            callback: function ($$v) {
              _vm.$set(
                this.$store.state.appointment,
                "isEditAppointmentVisible",
                $$v
              )
            },
            expression:
              "this.$store.state.appointment.isEditAppointmentVisible",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "u-blue-text" }, [
                _c("p", { staticStyle: { width: "100%" } }, [
                  _vm.matchedWindowsStep(1)
                    ? _c("i", {
                        class: [
                          _vm.isAppointmentEditable(null)
                            ? "mdi mdi-square-edit-outline"
                            : "mdi mdi-eye",
                        ],
                      })
                    : _vm._e(),
                  _vm.matchedWindowsStep(2)
                    ? _c("i", {
                        staticClass: "mdi mdi-chevron-left",
                        on: {
                          click: function ($event) {
                            return _vm.setFirstStep()
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.matchedWindowsStep(1)
                          ? _vm.isAppointmentEditable(null)
                            ? _vm.$t("EditAppointment")
                            : _vm.$t("ViewAppointment")
                          : _vm.$t("ConfirmAppointment")
                      ) +
                      " "
                  ),
                  _c("i", {
                    staticClass: "mdi mdi-close-circle-outline float-right",
                    staticStyle: { cursor: "pointer" },
                    on: { click: _vm.closeEditDialog },
                  }),
                ]),
              ]),
              _c("v-card-text", [_c("edit-appointment")], 1),
              _c("v-card-actions"),
            ],
            1
          ),
        ],
        1
      ),
      _c("message-dialog-component", {
        attrs: {
          "contact-full-name": _vm.makeSMSAndCall.contactFullName,
          "contact-phone-number": _vm.makeSMSAndCall.contactPhoneNumber,
          "show-message-dlg": _vm.makeSMSAndCall.showSendMessageDialog,
        },
        on: {
          hideSendMessageDialog: function ($event) {
            _vm.makeSMSAndCall.showSendMessageDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }