var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        [
          _vm.errorMessage !== ""
            ? _c(
                "v-alert",
                {
                  attrs: {
                    border: "right",
                    "colored-border": "",
                    type: "error",
                    elevation: "2",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
              )
            : _vm._e(),
          _c("v-text-field", {
            staticStyle: { width: "50% !important" },
            attrs: {
              clearable: "",
              flat: "",
              "hide-details": "",
              "prepend-inner-icon": "mdi-magnify",
              label: "Search",
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _c("v-data-table", {
            staticClass: "elevation-1 mt-10",
            staticStyle: { width: "100% !important" },
            attrs: {
              headers: _vm.headers,
              items: _vm.clients,
              "items-per-page": 15,
              loading: _vm.loading,
              "loading-text": "Loading... Please wait",
              search: _vm.search,
              "sort-by": "id",
              "sort-desc": true,
            },
            scopedSlots: _vm._u([
              {
                key: "item.actions",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-icon",
                      {
                        staticClass: "align-self-baseline",
                        on: {
                          click: function ($event) {
                            return _vm.showDetails(item)
                          },
                        },
                      },
                      [_vm._v(" mdi mdi-24px mdi-launch ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }