var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "div",
        {
          staticStyle: {
            color: "black",
            width: "80px !important",
            "font-size": "0.85em",
          },
        },
        [
          _vm.showAddButton
            ? _c(
                "v-btn",
                {
                  staticStyle: { cursor: "pointer", "margin-top": "3px" },
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.displayAddNoteDialog()
                    },
                  },
                },
                [
                  _c("v-icon", { staticStyle: { color: "#423f3f" } }, [
                    _vm._v("mdi-note-plus"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-badge",
                        {
                          attrs: {
                            content: _vm.notesCount,
                            value: _vm.notesCount,
                            color: "red",
                            overlap: "",
                            "offset-y": "14",
                            "offset-x": "12",
                          },
                        },
                        [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticStyle: {
                                    cursor: "pointer",
                                    "margin-top": "3px",
                                    "margin-left": "5px",
                                  },
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showAppointmentNoteDlg()
                                    },
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                { staticStyle: { color: "#423f3f" } },
                                [_vm._v("mdi-note-multiple")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Notes")])]
          ),
        ],
        1
      ),
      _c("add-note-dialog-component", {
        attrs: {
          "appointment-id": _vm.appointmentId,
          "cc-agent-id": _vm.ccAgentId,
          "show-add-note-dialog": _vm.showAddNoteDialog,
        },
        on: { noteAdded: _vm.noteAdd },
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.showAppointmentNotesDialog,
            callback: function ($$v) {
              _vm.showAppointmentNotesDialog = $$v
            },
            expression: "showAppointmentNotesDialog",
          },
        },
        [
          _c("appointment-notes-component", {
            key: _vm.showId,
            attrs: { "appointment-id": _vm.appointmentId },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }