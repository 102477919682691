var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-container" },
    [
      _c(
        "v-tabs",
        {
          staticClass: "tabs-style",
          attrs: {
            "background-color": "tabs-background",
            dark: "",
            "icons-and-text": "",
          },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("v-tabs-slider", {
            staticStyle: { display: "none" },
            attrs: { color: "#03a84e" },
          }),
          _c(
            "v-tab",
            { staticClass: "v-tab-style", attrs: { href: "#tab-1" } },
            [_vm._v(" Clients ")]
          ),
          _c(
            "v-tab",
            { staticClass: "v-tab-style", attrs: { href: "#tab-2" } },
            [_vm._v(" Technicians ")]
          ),
          _c(
            "v-tab",
            { staticClass: "v-tab-style", attrs: { href: "#tab-3" } },
            [_vm._v(" Appointments ")]
          ),
          _c(
            "v-tab",
            {
              staticClass: "v-tab-style",
              attrs: {
                href: "#tab-4",
                disabled:
                  _vm.contactCenterState.showClientDetailsWithId === -1 &&
                  (_vm.clientPhoneNumber === "" ||
                    _vm.clientPhoneNumber === "0"),
              },
            },
            [_vm._v(" Auto-Estimator ")]
          ),
          _c(
            "v-tab",
            {
              staticClass: "v-tab-style",
              attrs: {
                href: "#tab-5",
                disabled:
                  _vm.contactCenterState.showClientDetailsWithId === -1 &&
                  (_vm.clientPhoneNumber === "" ||
                    _vm.clientPhoneNumber === "0"),
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    "Saved Estimates " +
                      (_vm.contactCenterState.saveEstimatesCount > 0
                        ? "(" + _vm.contactCenterState.saveEstimatesCount + ")"
                        : "")
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "v-tab",
            { staticClass: "v-tab-style", attrs: { href: "#tab-6" } },
            [_vm._v(" Tickets ")]
          ),
          _c(
            "v-tab",
            { staticClass: "v-tab-style", attrs: { href: "#tab-7" } },
            [_vm._v(" Available Slots ")]
          ),
          _c(
            "v-tab",
            {
              staticClass: "v-tab-style d-flex flex-row",
              attrs: { href: "#tab-8" },
            },
            [
              _vm._v(" Notifications "),
              _vm.contactCenterState.notificationTabState
                .totalPendingNotification > 0
                ? _c("div", { staticClass: "numberCircle" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.contactCenterState.notificationTabState
                            .totalPendingNotification
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        _vm._l(8, function (i) {
          return _c(
            "v-tab-item",
            {
              key: i,
              staticClass: "tab-item-style",
              attrs: { value: "tab-" + i },
            },
            [
              _c(_vm.tabs[i - 1], {
                tag: "component",
                attrs: {
                  clientPhoneNumber: _vm.clientPhoneNumber,
                  userId: _vm.userId,
                  agentId: _vm.agentId,
                },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }