var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "pl-4 pt-5 pr-5", attrs: { "no-gutters": "" } },
        [
          _c("v-text-field", {
            staticStyle: { "max-width": "410px" },
            attrs: {
              clearable: "",
              flat: "",
              "hide-details": "",
              "prepend-inner-icon": "mdi-magnify",
              label: "Search",
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticStyle: { "font-family": "Rubik" },
              attrs: {
                elevation: "0",
                height: "48",
                color: "u-background-primary white--text text-none",
              },
              on: {
                click: function ($event) {
                  return _vm.doTicket()
                },
              },
            },
            [_vm._v(" Create Ticket ")]
          ),
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-1 mt-5",
        attrs: {
          headers: _vm.headers,
          items: _vm.tickets,
          "items-per-page": 15,
          loading: _vm.loading,
          "loading-text": "Loading... Please wait",
          search: _vm.search,
          "sort-by": ["status", "ticketNumber"],
          "sort-desc": [true, true],
          "single-expand": true,
          expanded: _vm.itemExpanded,
          "item-key": "ticketNumber",
          "show-expand": "",
        },
        on: {
          "update:expanded": function ($event) {
            _vm.itemExpanded = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item.fullName",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        (item.contact !== null ? item.contact.firstName : "") +
                          " " +
                          "" +
                          (item.contact !== null ? item.contact.lastName : "")
                      ) +
                      " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "item.agentName",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        (item.assignee !== null
                          ? item.assignee.firstName
                          : "") +
                          " " +
                          "" +
                          (item.assignee !== null ? item.assignee.lastName : "")
                      )
                  ),
                ]),
              ]
            },
          },
          {
            key: "expanded-item",
            fn: function (ref) {
              var headers = ref.headers
              var item = ref.item
              return [
                _c("TicketDetailsComponent", {
                  key: item.ticketNumber,
                  tag: "component",
                  attrs: { headers: headers, ticket: item },
                }),
              ]
            },
          },
          {
            key: "item.actions",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  [
                    item.status !== "Closed"
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            { attrs: { icon: "" } },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { color: "#198655" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.commentTicket(item)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " mdi-comment-plus-outline "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Add Comment")])]
                        )
                      : _vm._e(),
                    item.status !== "Closed"
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            { attrs: { icon: "" } },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { color: "red" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.closeTicket(item)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " mdi-close-circle-outline "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Close Ticket")])]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "item.createdTime",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("span", [
                  _vm._v(_vm._s(new Date(item.createdTime).toLocaleString())),
                ]),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "860" },
          model: {
            value: this.contactCenterClientState.showTicketDialog,
            callback: function ($$v) {
              _vm.$set(this.contactCenterClientState, "showTicketDialog", $$v)
            },
            expression: "this.contactCenterClientState.showTicketDialog",
          },
        },
        [_c("add-ticket-component")],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "860" },
          model: {
            value: _vm.showCommentDialog,
            callback: function ($$v) {
              _vm.showCommentDialog = $$v
            },
            expression: "showCommentDialog",
          },
        },
        [
          _c("add-ticket-comment-component", {
            attrs: { ticketId: _vm.selectedTicketId },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "500" },
          model: {
            value: _vm.showConfirmDialog,
            callback: function ($$v) {
              _vm.showConfirmDialog = $$v
            },
            expression: "showConfirmDialog",
          },
        },
        [
          _c("confirm-dialog-component", {
            key: new Date().valueOf(),
            attrs: {
              "ticket-id": _vm.selectedTicketId,
              title: "Are you sure you want to close this ticket?",
              message:
                "The ticket will be close permanently. Please confirm your want to close it.",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }