var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "no-gutters" },
    [
      _c(
        "v-col",
        {
          staticClass: "flex-grow-1 flex-shrink-0",
          staticStyle: { border: "1px solid #1fae79", "border-radius": "3px" },
        },
        [
          _c(
            "v-responsive",
            {
              staticClass: "overflow-y-hidden fill-height",
              attrs: { height: "500" },
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "d-flex flex-column fill-height",
                  attrs: { flat: "" },
                },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "d-flex justify-lg-space-between",
                      staticStyle: { "border-bottom": "3px solid #1fae79" },
                    },
                    [
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v("Agent Notes"),
                      ]),
                      _c("i", {
                        staticClass: "mdi mdi-close",
                        staticStyle: { "font-size": "24px", color: "#72767E" },
                        on: {
                          click: function ($event) {
                            return _vm.closeDialog()
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "flex-grow-1 overflow-y-auto pt-3 pb-2" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.loadingAppointmentCcNotes,
                              expression: "loadingAppointmentCcNotes",
                            },
                          ],
                          staticClass:
                            "d-flex w-100 justify-center align-center",
                        },
                        [
                          _c("v-progress-circular", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.loadingAppointmentCcNotes,
                                expression: "loadingAppointmentCcNotes",
                              },
                            ],
                            attrs: { color: "green", indeterminate: "" },
                          }),
                          _vm.thereArentNotes !== ""
                            ? _c("span", [_vm._v(_vm._s(_vm.thereArentNotes))])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._l(_vm.appointmentCcNotes, function (note) {
                        return [
                          _c(
                            "div",
                            { key: note.id },
                            [
                              _c(
                                "v-chip",
                                {
                                  staticClass: "pa-4 mb-2 w-100 col-12",
                                  staticStyle: {
                                    height: "auto",
                                    "white-space": "normal",
                                  },
                                  attrs: { dark: "" },
                                },
                                [
                                  _c("div", { staticClass: "row w-100" }, [
                                    _c("div", { staticClass: "col-12 pb-0" }, [
                                      _vm._v(
                                        " Agent: " +
                                          _vm._s(note.ccAgentName) +
                                          " "
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "col-12 pt-2 pb-0" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            " " + _vm._s(note.content) + " "
                                          ),
                                        ]),
                                        note.images.length
                                          ? _c(
                                              "v-card",
                                              {
                                                staticClass: "mt-2",
                                                attrs: {
                                                  "max-width": "100%",
                                                  outlined: "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-carousel",
                                                  {
                                                    attrs: {
                                                      height: "auto",
                                                      cycle: false,
                                                      "show-arrows":
                                                        note.images.length > 1,
                                                      "hide-delimiters":
                                                        "!(note.images.length > 1)",
                                                    },
                                                  },
                                                  _vm._l(
                                                    note.images,
                                                    function (image, idx) {
                                                      return _c(
                                                        "v-carousel-item",
                                                        {
                                                          key: idx,
                                                          attrs: {
                                                            src: image.imageUrl,
                                                            "reverse-transition":
                                                              "fade-transition",
                                                            transition:
                                                              "fade-transition",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openTargetNewWindows(
                                                                image.imageUrl
                                                              )
                                                            },
                                                          },
                                                        }
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col-12 pt-2",
                                        staticStyle: {
                                          "font-size": "0.7rem",
                                          "min-width": "42px !important",
                                          "text-align": "end",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              note.createdAt
                                                .toString()
                                                .split("T")[0]
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }