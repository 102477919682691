import { render, staticRenderFns } from "./AddTicketCommentComponent.vue?vue&type=template&id=f9b61cd2&scoped=true&"
import script from "./AddTicketCommentComponent.vue?vue&type=script&lang=ts&"
export * from "./AddTicketCommentComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9b61cd2",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VAutocomplete,VBtn,VCard,VCardActions,VCardText,VCardTitle,VCol,VContainer,VForm,VRow,VTextarea,VWindow,VWindowItem})


/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/uSacGCCn/0/ucarok/mobileservices-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f9b61cd2')) {
      api.createRecord('f9b61cd2', component.options)
    } else {
      api.reload('f9b61cd2', component.options)
    }
    module.hot.accept("./AddTicketCommentComponent.vue?vue&type=template&id=f9b61cd2&scoped=true&", function () {
      api.rerender('f9b61cd2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/contact-center/tabs/TicketsTabSubComponents/AddTicketCommentComponent.vue"
export default component.exports