import { render, staticRenderFns } from "./ClientTicketsComponent.vue?vue&type=template&id=28ab9697&scoped=true&"
import script from "./ClientTicketsComponent.vue?vue&type=script&lang=ts&"
export * from "./ClientTicketsComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ab9697",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCol,VRow,VTab,VTabItem,VTabs,VTabsItems})


/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/uSacGCCn/0/ucarok/mobileservices-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('28ab9697')) {
      api.createRecord('28ab9697', component.options)
    } else {
      api.reload('28ab9697', component.options)
    }
    module.hot.accept("./ClientTicketsComponent.vue?vue&type=template&id=28ab9697&scoped=true&", function () {
      api.rerender('28ab9697', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/contact-center/tabs/ClientTabSubComponents/ClientTicketsComponent.vue"
export default component.exports