var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      attrs: { "max-width": "860", id: "register" },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.enterKeyPressed($event)
        },
      },
    },
    [
      _c("v-card-title", { staticClass: "d-flex justify-end" }, [
        _c("i", {
          staticClass: "mdi mdi-close",
          staticStyle: { "font-size": "24px", color: "#72767E" },
          on: { click: _vm.closeSignUpDialog },
        }),
      ]),
      _c("div", { staticClass: "mb-12" }, [
        _c("h3", { staticClass: "text-center my-0" }, [
          _vm._v("Create Ticket"),
        ]),
      ]),
      _c(
        "v-window",
        [
          _c(
            "v-window-item",
            { attrs: { value: 1 } },
            [
              _c(
                "v-card-text",
                { staticClass: "pt-0 pb-0" },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-form",
                        {
                          ref: "addTicketForm",
                          attrs: { id: "add-tikect-form" },
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "pt-1" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pb-0 pt-0",
                                  attrs: {
                                    md: "6",
                                    lg: "6",
                                    xl: "6",
                                    cols: "12",
                                  },
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "font-family-rubik text--black",
                                    },
                                    [_vm._v("Contact Name")]
                                  ),
                                  _c("v-autocomplete", {
                                    key: _vm.contact,
                                    attrs: {
                                      outlined: "",
                                      "no-filter": true,
                                      items: _vm.contacts,
                                      "item-text": "description",
                                      "item-value": "id",
                                      "search-input": _vm.findingContacts,
                                      loading: _vm.loadingContacts,
                                      placeholder: _vm.$t("Select Contact"),
                                      rules: [
                                        function (v) {
                                          return (
                                            !!v || this$1.returnErrorMessage(6)
                                          )
                                        },
                                      ],
                                      disabled: _vm.creatingTicket,
                                    },
                                    on: {
                                      "update:searchInput": function ($event) {
                                        _vm.findingContacts = $event
                                      },
                                      "update:search-input": function ($event) {
                                        _vm.findingContacts = $event
                                      },
                                    },
                                    model: {
                                      value: _vm.contact,
                                      callback: function ($$v) {
                                        _vm.contact = $$v
                                      },
                                      expression: "contact",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pb-0 pt-0",
                                  attrs: {
                                    md: "6",
                                    lg: "6",
                                    xl: "6",
                                    cols: "12",
                                  },
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "font-family-rubik text--black",
                                    },
                                    [_vm._v("Priority")]
                                  ),
                                  _c("v-autocomplete", {
                                    key: _vm.priority,
                                    attrs: {
                                      outlined: "",
                                      "no-filter": true,
                                      items: _vm.priorities,
                                      "item-text": "description",
                                      "item-value": "value",
                                      placeholder: _vm.$t("Select Status"),
                                      rules: [
                                        function (v) {
                                          return (
                                            !!v || this$1.returnErrorMessage(6)
                                          )
                                        },
                                      ],
                                      disabled: _vm.creatingTicket,
                                    },
                                    model: {
                                      value: _vm.priority,
                                      callback: function ($$v) {
                                        _vm.priority = $$v
                                      },
                                      expression: "priority",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pb-0 pt-0",
                                  attrs: {
                                    md: "6",
                                    lg: "6",
                                    xl: "6",
                                    cols: "12",
                                  },
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "font-family-rubik text--black",
                                    },
                                    [_vm._v("Department")]
                                  ),
                                  _c("v-autocomplete", {
                                    key: _vm.department,
                                    attrs: {
                                      outlined: "",
                                      "no-filter": true,
                                      items: _vm.departments,
                                      "item-text": "name",
                                      "item-value": "id",
                                      placeholder: _vm.$t("Select Status"),
                                      rules: [
                                        function (v) {
                                          return (
                                            !!v || this$1.returnErrorMessage(6)
                                          )
                                        },
                                      ],
                                      disabled: _vm.creatingTicket,
                                    },
                                    model: {
                                      value: _vm.department,
                                      callback: function ($$v) {
                                        _vm.department = $$v
                                      },
                                      expression: "department",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "pt-0" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "font-family-rubik text--black",
                                    },
                                    [_vm._v("Supervisor")]
                                  ),
                                  _c("v-autocomplete", {
                                    key: _vm.agent,
                                    attrs: {
                                      "no-filter": true,
                                      outlined: "",
                                      items: _vm.agents,
                                      "item-text": "name",
                                      "item-value": "id",
                                      placeholder: _vm.$t("Select Agent"),
                                      rules: [
                                        function (v) {
                                          return (
                                            !!v || this$1.returnErrorMessage(6)
                                          )
                                        },
                                      ],
                                      disabled: _vm.creatingTicket,
                                    },
                                    model: {
                                      value: _vm.agent,
                                      callback: function ($$v) {
                                        _vm.agent = $$v
                                      },
                                      expression: "agent",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pb-0 pt-0",
                                  attrs: {
                                    md: "12",
                                    lg: "12",
                                    xl: "12",
                                    cols: "12",
                                  },
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "font-family-rubik text--black",
                                    },
                                    [_vm._v("Subject")]
                                  ),
                                  _c("v-text-field", {
                                    attrs: {
                                      placeholder: _vm.Subject,
                                      outlined: "",
                                      disabled: _vm.creatingTicket,
                                      rules: [
                                        function (v) {
                                          return (
                                            !!v || this$1.returnErrorMessage(1)
                                          )
                                        },
                                      ],
                                    },
                                    model: {
                                      value: _vm.subject,
                                      callback: function ($$v) {
                                        _vm.subject = $$v
                                      },
                                      expression: "subject",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pb-0 pt-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "font-family-rubik text--black",
                                    },
                                    [_vm._v("Description")]
                                  ),
                                  _c("v-textarea", {
                                    attrs: {
                                      outlined: "",
                                      disabled: _vm.creatingTicket,
                                      rules: [
                                        function (v) {
                                          return (
                                            !!v || this$1.returnErrorMessage(2)
                                          )
                                        },
                                      ],
                                    },
                                    model: {
                                      value: _vm.description,
                                      callback: function ($$v) {
                                        _vm.description = $$v
                                      },
                                      expression: "description",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "d-flex flex-row justify-end pr-3" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { "font-family": "Rubik" },
                                  attrs: {
                                    elevation: "0",
                                    height: "48",
                                    color:
                                      "u-background-primary white--text text-none",
                                    loading: _vm.creatingTicket,
                                    disabled: _vm.creatingTicket,
                                    form: "login-form",
                                  },
                                  on: { click: _vm.makeTicket },
                                },
                                [_vm._v(" Create ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-card-actions"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }