var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticStyle: { "max-width": "500px" } },
    [
      _c("v-card-title", { staticClass: "text-h5" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _c("v-card-text", [_vm._v(" " + _vm._s(_vm.message) + " ")]),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: "darken-1" },
              on: {
                click: function ($event) {
                  return _vm.setAgree(false)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.cancelButtonLabel) + " ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { color: "u-background-primary white--text" },
              on: {
                click: function ($event) {
                  return _vm.setAgree(true)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.acceptButtonLabel) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }