var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("v-text-field", {
                staticStyle: { "max-width": "410px" },
                attrs: {
                  "prepend-inner-icon": "mdi-magnify",
                  label: "Search",
                  "single-line": "",
                  clearable: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _c("v-spacer"),
              _vm.savedEstimates.length > 0
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "align-self-baseline",
                                        attrs: {
                                          slot: "activator",
                                          color: "red",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showConfirmDialog(
                                              "Are you sure you want to Delete ALL saved estimates?",
                                              -1,
                                              true
                                            )
                                          },
                                        },
                                        slot: "activator",
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v(" mdi-delete-sweep mdi-36px ")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2747566551
                      ),
                    },
                    [_c("span", [_vm._v("Delete all saved estimates")])]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.savedEstimates,
              search: _vm.search,
              loading: _vm.loading,
              "loading-text": "Loading... Please wait",
              "single-expand": "",
              expanded: _vm.expanded,
              "item-key": "id",
              "show-expand": "",
            },
            on: {
              "update:expanded": function ($event) {
                _vm.expanded = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "expanded-item",
                fn: function (ref) {
                  var headers = ref.headers
                  var item = ref.item
                  return [
                    _c(
                      "td",
                      { attrs: { colspan: headers.length / 1.5 } },
                      [
                        _c("v-data-table", {
                          staticStyle: {
                            width: "50%",
                            margin: "5px 5px 5px 15px",
                          },
                          attrs: {
                            dense: "",
                            headers: _vm.detailsHeaders,
                            items: item.estimate.services,
                            "hide-default-header": "",
                            "hide-default-footer": "",
                            "disable-pagination": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item.estimateCosts.totalPromCost",
                                fn: function (ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          width: "110px",
                                          "text-align": "right",
                                          "padding-top": "0",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                item.estimateCosts.totalPromCost
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "item.view",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-icon",
                      {
                        staticClass: "align-self-baseline",
                        on: {
                          click: function ($event) {
                            return _vm.showConfirmDialog(
                              "Are you sure you want to Load this Estimate?",
                              item.id,
                              false
                            )
                          },
                        },
                      },
                      [_vm._v(" mdi mdi-24px mdi-launch ")]
                    ),
                  ]
                },
              },
              {
                key: "item.delete",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "align-self-baseline",
                                          attrs: {
                                            slot: "activator",
                                            color: "red",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showConfirmDialog(
                                                "Are you sure you want to Delete this Estimate?",
                                                item.id,
                                                true
                                              )
                                            },
                                          },
                                          slot: "activator",
                                        },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        " mdi mdi-24px mdi-delete-outline "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("span", [_vm._v("Delete this Estimate")])]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.confirmDialog,
            callback: function ($$v) {
              _vm.confirmDialog = $$v
            },
            expression: "confirmDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v(" Confirm "),
              ]),
              _c(
                "v-card-text",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticStyle: { flex: "0" } },
                        [
                          !this.idDeleting
                            ? _c("v-icon", { attrs: { color: "blue" } }, [
                                _vm._v(" mdi-48px mdi-minus-circle "),
                              ])
                            : _vm._e(),
                          this.idDeleting
                            ? _c("v-icon", { attrs: { color: "red" } }, [
                                _vm._v(" mdi-48px mdi-minus-circle "),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticStyle: { flex: "1", "padding-top": "25px" } },
                        [_vm._v(" " + _vm._s(this.confirmDialogText) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "green darken-1",
                        text: "",
                        outlined: this.idDeleting,
                      },
                      on: {
                        click: function ($event) {
                          _vm.confirmDialog = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "green darken-1",
                        text: "",
                        outlined: !this.idDeleting,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.processEstimate()
                        },
                      },
                    },
                    [_vm._v(" Ok ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }