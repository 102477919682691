var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        [
          _c(
            "v-row",
            [
              _c(
                "v-icon",
                {
                  on: {
                    click: function ($event) {
                      return _vm.showTechClientApptMessages()
                    },
                  },
                },
                [_vm._v(" mdi mdi-24px mdi-eye ")]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "460", persistent: "" },
                  model: {
                    value: _vm.showSendMessageDialog,
                    callback: function ($$v) {
                      _vm.showSendMessageDialog = $$v
                    },
                    expression: "showSendMessageDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    { attrs: { id: "sendMessage", "max-width": "860" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "d-flex justify-lg-space-between" },
                        [
                          _c("span", { staticClass: "ml-1" }, [
                            _vm._v("Appointment messages"),
                          ]),
                          _c("i", {
                            staticClass: "mdi mdi-close",
                            staticStyle: {
                              "font-size": "24px",
                              color: "#72767E",
                            },
                            on: { click: _vm.closeSignUpDialog },
                          }),
                        ]
                      ),
                      _c(
                        "v-window",
                        [
                          _c(
                            "v-window-item",
                            { attrs: { value: 1 } },
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "pt-0 pb-0" },
                                [
                                  _c(
                                    "v-container",
                                    [
                                      _vm.loadingMessages
                                        ? _c(
                                            "v-row",
                                            [
                                              _c("v-progress-circular", {
                                                attrs: {
                                                  indeterminate: "",
                                                  color: "green",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-row",
                                        [
                                          _vm.messages.length > 0
                                            ? _c(
                                                "v-col",
                                                [
                                                  _c("message-list", {
                                                    attrs: {
                                                      messages: _vm.messages,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.messages.length === 0
                                            ? _c("v-col", [
                                                _c("h3", [
                                                  _vm._v(
                                                    "There aren't messages for this appointment."
                                                  ),
                                                ]),
                                              ])
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-card-actions"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }