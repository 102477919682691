var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "elevation-1",
    attrs: {
      headers: _vm.headers,
      items: _vm.upcomingAppointments,
      "items-per-page": 5,
      loading: _vm.loading,
      "loading-text": "Loading... Please wait",
    },
    scopedSlots: _vm._u([
      {
        key: "item.appmessages",
        fn: function (ref) {
          var item = ref.item
          return [
            _c("appt-messages-component", {
              staticStyle: { "margin-left": "10px" },
              attrs: {
                "tuple-id": item.clientId,
                "user-id":
                  _vm.contactCenterClientState.showTechnicianDetailsWithId,
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }