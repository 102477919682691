var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.userAppointmentInfo.pastAppointments,
          "items-per-page": 5,
          loading: _vm.loading,
          "loading-text": "Loading... Please wait",
          "sort-by": ["id"],
          "sort-desc": [true],
        },
        scopedSlots: _vm._u([
          {
            key: "item.actions",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("message-and-call-component", {
                  attrs: {
                    "contact-full-name": item.technician,
                    "contact-phone-number": item.mechanicPhone,
                    "agent-id": _vm.agentId,
                  },
                }),
              ]
            },
          },
          {
            key: "item.appmessages",
            fn: function (ref) {
              var item = ref.item
              return [
                item.vehicle !== null
                  ? _c("appt-messages-component", {
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        "tuple-id": item.vehicle.mTupleId,
                        "user-id":
                          _vm.contactCenterClientState.showClientDetailsWithId,
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "item.status",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "v-chip",
                  {
                    staticClass: "px-2",
                    attrs: {
                      "x-small": "",
                      color: _vm.getStatusColor(item.status),
                      dark: "",
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.getStatusCaption(item.status)) + " "
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.edit",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "v-icon",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.editMyAppointment(item)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.isAppointmentEditable(item)
                            ? "mdi-pencil"
                            : "mdi-eye"
                        ) +
                        " "
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.noteActions",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("add-and-notes-component", {
                  attrs: {
                    "appointment-id": item.id,
                    "cc-agent-id": _vm.agentId,
                    ccNotesCount: item.ccNotesCount,
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "490" },
          model: {
            value: this.$store.state.appointment.isEditAppointmentVisible,
            callback: function ($$v) {
              _vm.$set(
                this.$store.state.appointment,
                "isEditAppointmentVisible",
                $$v
              )
            },
            expression:
              "this.$store.state.appointment.isEditAppointmentVisible",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "u-blue-text" }, [
                _c("p", { staticStyle: { width: "100%" } }, [
                  _vm.matchedWindowsStep(1)
                    ? _c("i", {
                        class: [
                          _vm.isAppointmentEditable(null)
                            ? "mdi mdi-square-edit-outline"
                            : "mdi mdi-eye",
                        ],
                      })
                    : _vm._e(),
                  _vm.matchedWindowsStep(2)
                    ? _c("i", {
                        staticClass: "mdi mdi-chevron-left",
                        on: {
                          click: function ($event) {
                            return _vm.setFirstStep()
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.matchedWindowsStep(1)
                          ? _vm.isAppointmentEditable(null)
                            ? _vm.$t("EditAppointment")
                            : _vm.$t("ViewAppointment")
                          : _vm.$t("ConfirmAppointment")
                      ) +
                      " "
                  ),
                  _c("i", {
                    staticClass: "mdi mdi-close-circle-outline float-right",
                    staticStyle: { cursor: "pointer" },
                    on: { click: _vm.closeEditDialog },
                  }),
                ]),
              ]),
              _c("v-card-text", [_c("edit-appointment")], 1),
              _c("v-card-actions"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }