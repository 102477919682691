var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "my-0 py-0" },
    [
      _c(
        "v-col",
        { staticClass: "col-12 py-0" },
        [
          _c("v-autocomplete", {
            key: _vm.address,
            attrs: {
              "no-filter": true,
              outlined: "",
              items: _vm.entries,
              loading: _vm.isLoading,
              disabled: _vm.disableElementsInternal,
              "search-input": _vm.search,
              placeholder: _vm.$t("Address"),
              label: _vm.$t("Address"),
            },
            on: {
              "update:searchInput": function ($event) {
                _vm.search = $event
              },
              "update:search-input": function ($event) {
                _vm.search = $event
              },
              change: function ($event) {
                return _vm.changeAddress()
              },
            },
            model: {
              value: _vm.address,
              callback: function ($$v) {
                _vm.address = $$v
              },
              expression: "address",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }